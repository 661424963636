.app-container {
  font-family: 'Arial', sans-serif;
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
  border: 1px solid #e0e0e0;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  
}
a:hover {
  color: green;
}
a:active {
  color: blue;
}
.container social {
  display: inline-block;
  width: 100px;
  height: 100px;
  border: 1px solid red;
}
social {
  display: flex;
  padding-left: 20px;
}
a {
  text-decoration: none;
}
Form {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 50px;
}
audio {
  display: none;
  padding-block: 20px;
}
.intro-container {
 text-align: justify;
 font-size: small;
 padding: 20px;
}


audio {
  display: none;
  padding-block: 20px;
}

.audio-loaded audio {
  display: block;
}

.alert {
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
}

.alert.error {
    background-color: #f44336; 
    color: white;
}

.alert.success {
    background-color: #4CAF50; 
    color: white;
}


.audio-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.audio-check {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.transcription-area {
  width: 100%;
  height: 150px;
  margin-top: 20px;
}

.transcriber-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.validation-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
p {
  margin: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  /* Espacio entre los botones */
}
.btn-transcribe {
  background-color: #4caf50;
  color: #fff;
}

.btn-transcribe.transcribing {
  background-color: #ffc107;
  color: black;
}



.btn-upload {
  background-color: #4caf50;
  color: #fff;
}

.btn-record {
  background-color: #4caf50;
  color: #fff;
}

.btn-record.is-recording {
  background-color: #f44336;
  color: #fff;
}

.btn-repeat {
  background-color: #b1b7b1;
  color: #f60606;
}

.btn-transcribe {
  background-color: #4caf50;
  color: #fff;
}

.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  /* color de fondo de la barra de progreso */
  margin-top: 10px;
  /* Espaciado entre el botón y la barra de progreso */
}

.progress-bar {
  height: 5px;
  /* Altura de la barra de progreso */
  background-color: green;
  /* Color de la barra de progreso */
}

.btn-validate {
  background-color: #4caf50;
  color: #fff;
}

.btn-correct {
  background-color: #f44336;
  color: #fff;
}

footer {
  margin-top: 20px;
  text-align: center;
}